import {
  MUX_DATA,
  PICKUP_4DOOR_DATA,
  SPARK_DATA,
  V_CROSS_DATA,
} from '@/contents/lcv/product/cafe/lineup';
import { IMy24LineupItem } from '@/types/shared';

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte456dec813c4503a/651668c0098aaa9f88ce49be/home-lineup-bg-v-cross-mobile.jpg',
      alt: V_CROSS_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt391cae8117816279/651668f5474d4d73914f465d/home-lineup-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      minWidth: true,
    },
    pricePrefix: 'តម្លែចាប់ពី',
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9f99dc2ea4f1adc2/65166a2623b7e78f5e6aa95c/home-lineup-bg-4-door-mobile.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteee13e08dc626ff6/651669f1ec958dbcd8ea56b4/home-lineup-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      minWidth: true,
    },
    pricePrefix: 'តម្លែចាប់ពី',
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
      alt: SPARK_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc51540266564afd1/651669a623b7e7b9f46aa95a/home-lineup-bg-spark-mobile.jpg',
      alt: SPARK_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt180e8b8b245e62df/651669cccf50bf3f2afffa5c/home-lineup-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      minWidth: true,
    },
    pricePrefix: 'តម្លែចាប់ពី',
    price: SPARK_DATA.price,
  },
  {
    id: 5,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta88051ed0845f653/65d2bf04cce422ad7c037d9c/cambodia-lineup-mu-x.png',
      alt: MUX_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3f7a4476cab98baa/65166abeb16d6deb10554c64/home-lineup-bg-mu-x-mobile.jpg',
      alt: MUX_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6c92f43d2807b858/65166a4e474d4db0ed4f4666/home-lineup-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: MUX_DATA.url,
      minWidth: true,
    },
    pricePrefix: 'តម្លែចាប់ពី',
    price: MUX_DATA.price,
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6ee10f4015aa408/65d2c00eebb2a2420d7b8b77/cambodia-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6ee10f4015aa408/65d2c00eebb2a2420d7b8b77/cambodia-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      minWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8728512a4319cf18/65d2c00e1671e63e2e2e9253/cambodia-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8728512a4319cf18/65d2c00e1671e63e2e2e9253/cambodia-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      minWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
      alt: SPARK_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt593e3db70bccee7f/65d2c00e27ec8db73e4a3e08/cambodia-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt593e3db70bccee7f/65d2c00e27ec8db73e4a3e08/cambodia-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      minWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 6,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta88051ed0845f653/65d2bf04cce422ad7c037d9c/cambodia-lineup-mu-x.png',
      alt: MUX_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt859ab8c8340f3ae3/65d2c00e0b928ca45d7cfaff/cambodia-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt859ab8c8340f3ae3/65d2c00e0b928ca45d7cfaff/cambodia-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      url: MUX_DATA.url,
      minWidth: true,
    },
    price: MUX_DATA.price,
  },
];
