import { ISrcset } from '@/components/shared/element/image';
import { IRatio } from '@/types/shared/my24/ratio';

interface IImageSrcset {
  mobileSrc: string;
  desktopSrc: string;
  cropByRatio?: IRatio;
  cropByRatioDesktop?: IRatio;
}
export const IMAGE_SRCSET_100VW = (src: IImageSrcset): ISrcset[] => {
  return [
    {
      src: src.mobileSrc,
      breakpoint: 0,
      width: 375,
      maxwidth: 375,
      crop: src?.cropByRatio,
    },
    {
      src: src.mobileSrc,
      breakpoint: 375,
      width: 414,
      maxwidth: 414,
      crop: src?.cropByRatio,
    },
    {
      src: src.mobileSrc,
      breakpoint: 600,
      width: 600,
      maxwidth: 600,
      crop: src?.cropByRatio,
    },
    {
      src: src.mobileSrc,
      breakpoint: 768,
      width: 768,
      maxwidth: 768,
      crop: src?.cropByRatio,
    },
    {
      src: src.desktopSrc,
      breakpoint: 1024,
      width: 1024,
      maxwidth: 840,
      crop: src?.cropByRatioDesktop,
    },
    {
      src: src.desktopSrc,
      breakpoint: 1200,
      width: 1200,
      maxwidth: 960,
      crop: src?.cropByRatioDesktop,
    },
    {
      src: src.desktopSrc,
      breakpoint: 1366,
      width: 1440,
      maxwidth: 1024,
      crop: src?.cropByRatioDesktop,
    },
    {
      src: src.desktopSrc,
      breakpoint: 1536,
      width: 1680,
      maxwidth: 1024,
      crop: src?.cropByRatioDesktop,
    },
    {
      src: src.desktopSrc,
      breakpoint: 1920,
      width: 1920,
      maxwidth: 1280,
      crop: src?.cropByRatioDesktop,
    },
  ];
};
