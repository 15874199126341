import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledBox = styled('div', {
  variants: {
    variant: {
      white: {
        bc: '$white',
      },
      gray: {
        bc: '$gray200',
      },
      black: {
        bc: '$black',
      },
    },
    border: {
      gray: {
        border: '1px solid $gray300',
      },
    },
    rounded: {
      '0-5': {
        br: '$rounded-0-5',
      },
      '1': {
        br: '$rounded-1',
      },
      '1-5': {
        br: '$rounded-1-5',
      },
      '2': {
        br: '$rounded-2',
      },
      '3': {
        br: '$rounded-3',
      },
      '4': {
        br: '$rounded-4',
      },
      '5': {
        br: '$rounded-5',
      },
      '6': {
        br: '$rounded-6',
      },
    },
    padding: {
      '16': {
        p: 16,
      },
      '24': {
        p: 24,
      },
      '1': {
        p: '$space-1',
      },
      '2': {
        p: '$space-2',
      },
      '3': {
        p: '$space-3',
      },
      '4': {
        p: '$space-4',
      },
      '5': {
        p: '$space-5',
      },
      '6': {
        p: '$space-6',
      },
    },
    position: {
      relative: {
        position: 'relative',
      },
      absolute: {
        position: 'absolute',
      },
      sticky: {
        position: 'sticky',
      },
      fixed: {
        position: 'fixed',
      },
    },
    width: {
      'w-full': {
        width: '100%',
      },
      'w-screen': {
        width: '100vw',
      },
      'w-auto': {
        width: 'auto',
      },
    },
    height: {
      'h-full': {
        height: '100%',
      },
      'h-screen': {
        height: '100vh',
      },
      'h-auto': {
        height: 'auto',
      },
    },
    inset: {
      '0': {
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
      },
      'x-0': {
        left: '0px',
        right: '0px',
      },
      'y-0': {
        top: '0px',
        bottom: '0px',
      },
    },
    top: {
      '0': {
        top: '0px',
      },
      auto: {
        top: 'auto',
      },
    },
    bottom: {
      '0': {
        bottom: '0px',
      },
      auto: {
        bottom: 'auto',
      },
    },
    left: {
      '0': {
        left: '0px',
      },
      auto: {
        left: 'auto',
      },
    },
    right: {
      '0': {
        right: '0px',
      },
      auto: {
        right: 'auto',
      },
    },
    overflow: {
      initial: {
        overflow: 'initial',
      },
      hidden: {
        overflow: 'hidden',
      },
    },
    zIndex: {
      0: {
        zIndex: 0,
      },
      1: {
        zIndex: 1,
      },
    },
    shadow: {
      1: {
        boxShadow: '$shadow100',
      },
    },
  },
});

interface IProps extends React.ComponentProps<typeof StyledBox> {
  children?: React.ReactNode;
  css?: any;
  className?: string;
  title?: string;
  tracking?: ITracking;
  test?: ITest;
  onClick?: () => void;
  onMouseOut?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hoverText?: string;
  hoverBg?: string;
  hoverChild?: string;
}

export const Box: React.FunctionComponent<IProps> = (props: IProps) => {
  const options = {
    variant: props.variant,
    border: props.border,
    rounded: props.rounded,
    padding: props.padding,
    position: props.position,
    width: props.width,
    height: props.height,
    inset: props.inset,
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,
    overflow: props.overflow,
    zIndex: props.zIndex,
  };
  return (
    <StyledBox
      aria-label={props?.title}
      {...options}
      css={props?.css}
      className={props?.className}
      onClick={props.onClick && props.onClick}
      onMouseOut={props.onMouseOut && props.onMouseOut}
      onMouseEnter={props.onMouseEnter && props.onMouseEnter}
      onMouseLeave={props.onMouseLeave && props.onMouseLeave}
      data-track={props.tracking && props.tracking.dataTrack}
      data-track-section={props.tracking && props.tracking.dataTrackSection}
      data-track-value={props.tracking && props.tracking.dataTrackValue}
      data-track-text={props.tracking && props.tracking.dataTrackText}
      data-track-url={props.tracking && props.tracking.dataTrackUrl}
      data-test={props.test && props.test.dataTest}
      data-hover-text={props?.hoverText}
      data-hover-bg={props?.hoverBg}
      data-hover-child={props?.hoverChild}
    >
      {props.children}
    </StyledBox>
  );
};
Box.displayName = 'SharedMY24Box';
Box.defaultProps = {};
