import { IMY24CTA } from '@/components/sections/my24/shared/cta';
import {
  MENU_LCV_ABOUT_CONTACT,
  MENU_LCV_CALCULATOR,
  MENU_LCV_REGISTER,
} from '@/contents/lcv/link';

export const getCta = (
  page:
    | 'home'
    | 'v-cross'
    | 'x-series'
    | 'mu-x'
    | 'pickup-2-door'
    | 'pickup-4-door'
    | 'spark'
) => {
  switch (page) {
    // case 'x-series':
    //   // return getProductCtaPup('?tag=x-series');
    // case 'v-cross':
    //   // return getProductCtaPup('?tag=v-cross');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6086e8b8b14bd693/650a549aff69780bd71c4122/v-cross.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt697f8d43d3fc83db/650a54b7b556bd7e2fd3b6d4/v-cross.jpg',
    //     '/v-cross-4-door-financial'
    //   );
    // case 'pickup-4-door':
    //   // return getProductCtaPup('?tag=hilander&tag=cab4');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt50396d0288863d76/650a5403c5537cf12c4d034c/4-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt07b9a569da35dd53/650a52cab29ddbdf60544d62/4-door.jpg',
    //     '/hilander-4-doors-financial'
    //   );
    // case 'pickup-2-door':
    //   // return getProductCtaPup('?tag=spacecab');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfc80906da208b0d6/651fb86662df4415576dc3c6/2-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0a5cee339d2ba81/651fb891705ef39e5348b5bd/2-door.jpg',
    //     '/spacecab-s-financial'
    //   );
    // case 'spark':
    //   // return getProductCtaPup('?tag=spark');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7dadc5cd4a0b21b/650a544b7e03024b28bbe95b/spark.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d9d47398075cb40/650a53bef0b16bdb0e1d9253/spark.jpg',
    //     '/spark-1-9-financial'
    //   );
    // case 'mu-x':
    //   return getProductCtaPpv();
    default:
      return getCtaDefault();
  }
};

const getCtaDefault = (): IMY24CTA => {
  return {
    active: true,
    headline: {
      title: 'CTA',
    },
    items: [
      {
        preTitle: 'Register',
        title: 'ចាប់អារម្មណ៍បើកបរសាកល្បង',

        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3073fc2fb0ec0135/6641e0594afe6c818ea6b8c4/offer-cta-mobile.jpg',
          alt: 'Register',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf96b3f435359398/6641e04866b81d377e33d33d/offer-cta-desktop.jpg',
          alt: 'Register',
        },
        buttons: [
          {
            label: 'មើលពត៌មានបន្ថែម',
            url: MENU_LCV_REGISTER.url,
            target: MENU_LCV_REGISTER.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
      {
        preTitle: 'Calculator',
        title: 'គណនាការបង់រំលស់',
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt46220bab39db9828/65d2beed27ec8d806f4a3e02/cambodia-cta-cal-m.jpg',
          alt: 'Calculator',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5f196664d80a8575/65d2beee7475762e5ae4701b/cambodia-cta-cal.jpg',
          alt: 'Calculator',
        },
        buttons: [
          {
            label: 'មើលពត៌មានបន្ថែម',
            url: MENU_LCV_CALCULATOR.url,
            target: MENU_LCV_CALCULATOR.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
      {
        preTitle: 'Contact Us',
        title: 'ទំនាក់ទំនងមក​យើងខ្ញុំ',
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1a9cab901cfb7f51/65d2beedc52088364f7f8931/cambodia-cta-contact-m.jpg',
          alt: 'Contact Us',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt017872a64321b9d1/65d2beede07e36cc8fe1b26a/cambodia-cta-contact.jpg',
          alt: 'Contact Us',
        },
        buttons: [
          {
            label: 'មើលពត៌មានបន្ថែម',
            url: MENU_LCV_ABOUT_CONTACT.url,
            target: MENU_LCV_ABOUT_CONTACT.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
    ],
    dataTrack: 'lcv-home',
  };
};
